import moment from 'moment';
import { convertUTCToIST } from '@utils/time.utils';
import { DateRangeFilterTypes } from 'src/typings/call.types';

export const DEFAULT_TIME_RANGE = 30;

export const getDefaultStartAndEndTime = (type: DateRangeFilterTypes): { start: Date; end: Date } => {
  switch (type) {
    case DateRangeFilterTypes.LastXMinutes:
      return getDefaultXMinuteFromNow();
    case DateRangeFilterTypes.Today:
      return getTodayStartAndEndTime();
    case DateRangeFilterTypes.Yesterday:
      return getYesterdayStartAndEndTime();
    default:
      return getDefaultXMinuteFromNow();
  }
};

export const checkIfStale = (start: string, end: string, type: DateRangeFilterTypes) => {
  const startDiffInMilliseconds = moment().diff(convertUTCToIST(start));
  const endDiffInMilliseconds = moment().diff(convertUTCToIST(end));
  const diffInHours = startDiffInMilliseconds / (1000 * 60 * 60);
  switch (type) {
    case DateRangeFilterTypes.LastXMinutes:
      const diffInMinutes = endDiffInMilliseconds / (1000 * 60);
      return diffInMinutes > 5;
    case DateRangeFilterTypes.Today:
      return diffInHours >= 24;
    case DateRangeFilterTypes.Yesterday:
      return diffInHours >= 48;
    case DateRangeFilterTypes.Custom:
      return false;
  }
};

export const getDefaultXMinuteFromNow = (): { start: Date; end: Date } => {
  const start = moment().subtract(DEFAULT_TIME_RANGE, 'minute').toDate();
  const end = moment().toDate();
  return { start, end };
};

export const getTodayStartAndEndTime = () => {
  let start = new Date();
  let end = new Date();
  // set start to 8AM
  start.setHours(8, 0, 0, 0);
  end.setHours(19, 0, 0, 0);
  // const maxEnd = new Date();
  // maxEnd.setHours(19, 0, 0, 0);
  // end = now < maxEnd ? now : maxEnd;

  return { start, end };
};

export const getYesterdayStartAndEndTime = () => {
  const now = new Date();
  let start = new Date();
  let end = new Date();
  start.setDate(now.getDate() - 1);
  end.setDate(now.getDate() - 1);
  // set start to 8AM and end to 7PM
  start.setHours(8, 0, 0, 0);
  end.setHours(19, 0, 0, 0);
  return { start, end };
};
