import { Avatar } from '@components/Avatar';
import { Popover, PopoverPosition } from '@components/Popover';
import {
  ChartBarIcon,
  ChevronLeftIcon,
  LogoutIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  ViewListIcon,
  TerminalIcon,
  UploadIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { IconButton } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/contexts/auth';
import { AuthContextInterface } from 'src/contexts/auth/AuthContext';
import { queryClient } from 'src/queryClient';
import { callLogs, companies } from 'src/queryClient/queryKeys';
import NavBarItem from './NavBarItem';

interface IProps {
  showBackButton?: boolean;
  pageTitle?: string;
}

export default function TopNavbar(props: IProps) {
  const { showBackButton = true, pageTitle = '' } = props;
  const avatarRef = useRef<HTMLDivElement>(null);
  const [showPopup, togglePopup] = useState<boolean>(false);
  const { isAdmin, company, setAuthInfo } = useContext(AuthContext) as AuthContextInterface;

  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;

  const navigate = useNavigate();

  const onGoToDashboard = () => navigate('/dashboard');

  const onGoToStats = () => navigate('/stats');

  const onGoToMLOps = () => navigate('/mlops');

  const onGoToUploader = () => navigate('/uploader');

  const onLogout = () => {
    localStorage.removeItem('en');
    localStorage.removeItem('user');
    localStorage.removeItem('at');
    localStorage.removeItem('rt');
    localStorage.removeItem('params');
    localStorage.removeItem('page');
    localStorage.removeItem('prevTab');
    localStorage.removeItem('company');
    queryClient.invalidateQueries([callLogs]);
    queryClient.invalidateQueries([companies]);
    navigate(`/login?referrer=${window.location.pathname}`);
    setAuthInfo(null);
  };

  return (
    <div className="flex justify-between items-center w-full h-14 border-b pl-3 pr-4 py-2 bg-hoverBgColor relative">
      <div className="flex justify-start items-center gap-x-2">
        {showBackButton ? (
          <IconButton title="Go Back" onClick={() => navigate('/dashboard')}>
            <ChevronLeftIcon className="w-6 h-6" />
          </IconButton>
        ) : (
          <div className="w-10 h-10"></div>
        )}
        <div className="font-bold text-lg">{pageTitle}</div>
      </div>
      <div
        className="flex justify-end items-center gap-x-4 relative hover:cursor-pointer"
        ref={avatarRef}
        onClick={() => togglePopup(true)}
      >
        <Avatar firstName={user?.first_name} lastName={user?.last_name} />
        <Popover
          contentPropClass="w-[280px] mr-2 overflow-hidden"
          showPopover={showPopup}
          contentPosition={PopoverPosition.BottomRight}
          onClose={() => togglePopup(false)}
        >
          <div className="flex flex-col w-full">
            <div className="flex justify-start items-center gap-x-2 p-2">
              <OfficeBuildingIcon className="w-5 h-5 text-primaryButton" />
              <div className="text-lg font-medium">{isAdmin ? 'Knowl' : company?.name}</div>
            </div>
            <hr className="w-full my-2" />
            {user && (
              <>
                <div className="flex justify-start items-center gap-x-2 p-2">
                  <UserIcon className="w-5 h-5 text-primaryButton" />
                  <div className="text-sm font-medium">{user.first_name + ' ' + user.last_name}</div>
                </div>
                <hr className="w-full my-2" />
              </>
            )}
            <NavBarItem
              title="Calling"
              onClick={() => navigate('/')}
              icon={<PhoneIcon className="w-5 h-5 text-primaryButton" />}
            />
            <NavBarItem
              title="Dashboard"
              onClick={onGoToDashboard}
              icon={<ViewListIcon className="w-5 h-5 text-primaryButton" />}
            />
            {false && (
              <NavBarItem
                title="Stats"
                onClick={onGoToStats}
                icon={<ChartBarIcon className="w-5 h-5 text-primaryButton" />}
              />
            )}
            <hr className="w-full my-2" />
            {isAdmin && (
              <>
                <NavBarItem
                  title="Upload"
                  onClick={onGoToUploader}
                  icon={<UploadIcon className="w-5 h-5 text-primaryButton" />}
                />
                <NavBarItem
                  title="ML Ops"
                  onClick={onGoToMLOps}
                  icon={<TerminalIcon className="w-5 h-5 text-primaryButton" />}
                />
                <hr className="w-full my-2" />
              </>
            )}
            <NavBarItem
              title="Logout"
              onClick={onLogout}
              icon={<LogoutIcon className="w-5 h-5 text-primaryButton" />}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
}
