import { useEffect, useRef, useState } from 'react';
//components
import { SecondaryButton } from '@components/Buttons';
import { DashboardLoader } from '@components/Loader';
import { UserIntentFilter } from '@components/UserIntentFilter';
import { UserPhoneFilter } from '@components/UserPhoneFilter';
import { UserDurationFilter } from '@components/UserDurationFilter';
import { UserDateRangeFilter } from '@components/UserDateRangeFilter';
import { UserDPDFilter } from '@components/UserDPDFilter';
import { UserLanguageFilter } from '@components/UserLanguageFilter';
import { UserOfferFilter } from '@components/UserOfferFilter';
import { InlineAudioBar } from '@components/InlineAudioBar';
import { TextField } from '@components/TextField';
import { Checkbox, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
//hooks
import { useGetCallLogs } from 'src/hooks/apis/calls';
//types
import { CallLog, CallType, DateRangeFilterTypes } from 'src/typings/call.types';
import { CompanyNode } from 'src/typings/user.types';
//utils
import { getEnumKeyByValue, getUserIntentReason } from '../../utils/call.utils';
import { openNewTab } from '@utils/window.utils';
import { getDataFromStorage, setDataInStorage } from '@utils/storageUtils';
//libraries
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { queryClient } from 'src/queryClient';
import { callLogs } from 'src/queryClient/queryKeys';
import { Languages } from 'src/constants/language';
import { UserAuditedByFilter } from '@components/UserAuditedByFilter';
import { getDefaultStartAndEndTime } from '@components/UserDateRangeFilter/utils';

type Props = {
  isAdmin: boolean;
  company: CompanyNode | null;
  onScrollToTop: () => void;
};

const enableRowSelection = false;
const size = 50;

const getDefaultParams = () => {
  const prevParams = getDataFromStorage('params');
  if (isEmpty(prevParams)) {
    const { start, end } = getDefaultStartAndEndTime(DateRangeFilterTypes.LastXMinutes);
    const defaultParams = {
      start_date: moment(start).utc().format('YYYY-MM-DDTHH:mm:ss'),
      end_date: moment(end).utc().format('YYYY-MM-DDTHH:mm:ss'),
      defaultDateRangeFilter: true,
      dateRangeFilterType: DateRangeFilterTypes.LastXMinutes,
      // add more default params here....
    };
    setDataInStorage('params', { ...defaultParams });
    return defaultParams;
  } else {
    return prevParams;
  }
};

export default function ActualCalls({ isAdmin, company, onScrollToTop }: Props) {
  const prevPage = getDataFromStorage('page');
  const prevParams = getDataFromStorage('params');
  const defaultParams = getDefaultParams();
  const [selectedItems, setSelectedItems] = useState<CallLog[]>([]);
  const [params, setParams] = useState<any>({ ...defaultParams });
  const [page, setPage] = useState<number>(prevPage || 1);
  const [pageValue, setPageValue] = useState<number>(prevPage || 1);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [disableNext, toggleDisableNext] = useState<boolean>(false);
  const iconButtonRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, isFetching } = useGetCallLogs({
    page,
    size,
    callType: CallType.REGULAR,
    companyId: company?.id === 'all' ? '' : company?.id,
    params: { ...params },
  });

  const handleFilterChange = (filters: any) => {
    setDataInStorage('params', { ...params, ...filters });
    queryClient.invalidateQueries([callLogs, CallType.REGULAR, page, company?.id, { ...params, ...filters }]);
    setParams({ ...params, ...filters });
    updatePage(1);
  };

  const actualCalls = data?.items.sort((a, b) => moment(b.call_start).diff(moment(a.call_start)));

  const onRowPress = (id: number | string) => {
    openNewTab(`/dashboard/${id}`);
  };

  const addItem = (log: CallLog) => {
    setSelectedItems([...selectedItems, log]);
  };

  const removeItem = (log: CallLog) => {
    setSelectedItems(selectedItems.filter((i) => i.id !== log.id));
  };

  const updatePage = (newPageNumber: number) => {
    setPage(newPageNumber);
    setPageValue(newPageNumber);
    setDataInStorage('page', newPageNumber);
    onScrollToTop();
  };

  const handlePageInputBlur = () => {
    try {
      const val = parseInt(pageValue, 10);
      // Commenting this out because pagination response from BE is changed again.
      // if (!isNumber(val) || !(pageValue > 0 && pageValue <= maxPage)) {
      //   toast.error(`Please enter a valid page number 1 and ${maxPage}`);
      //   updatePage(1);
      // } else {
      updatePage(val);
      //   }
    } catch (error) {
      //    toast.error(`Please enter a valid page number 1 and ${maxPage}`);
      updatePage(1);
    }
  };

  const onSelectRow = (e: React.MouseEvent<HTMLButtonElement>, log: CallLog) => {
    e.preventDefault();
    e.stopPropagation();

    const index = selectedItems?.findIndex((l) => l.id === log.id);
    if (index === -1) {
      addItem(log);
    } else {
      removeItem(log);
    }
  };

  useEffect(() => {
    if (data) {
      toggleDisableNext(data.items.length < size);
    }
  }, [data]);

  if (isLoading || isFetching) {
    return <DashboardLoader />;
  }

  return (
    <div className="w-full flex flex-col justify-start items-center gap-4">
      <div className="w-full flex justify-between items-center mt-5">
        <div className="text-left text-sm font-medium">Results</div>
      </div>
      <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
        <thead className="border p-2 bg-gray-100 w-full">
          <tr className="border p-2 w-full">
            {enableRowSelection && <td className="border p-2 text-sm font-bold text-blue-950"></td>}
            <td className="border p-1 text-sm font-bold text-blue-950">#</td>
            <td className="border p-1 text-sm font-bold text-blue-950">Borrower Name</td>
            <td className="border p-1 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>Call To</div>
                <UserPhoneFilter
                  onFilterChange={handleFilterChange}
                  prevFilter={get(prevParams, 'phone_numbers', null)}
                />
              </div>
            </td>
            <td className="border p-1 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>Duration</div>
                <UserDurationFilter
                  onFilterChange={handleFilterChange}
                  prevFilter={get(prevParams, 'duration', null)}
                />
              </div>
            </td>
            <td className="border p-1 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>User Intent</div>
                <UserIntentFilter
                  intentType={'intent'}
                  onFilterChange={handleFilterChange}
                  prevFilter={get(prevParams, 'intent', [])}
                />
              </div>
            </td>

            <td className="border p-1 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>DPD</div>
                <UserDPDFilter
                  onFilterChange={handleFilterChange}
                  prevFilter={{
                    dpd_min: get(prevParams, 'dpd_min', null),
                    dpd_max: get(prevParams, 'dpd_max', null),
                  }}
                />
              </div>
            </td>
            <td className="border p-1 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>Language</div>
                <UserLanguageFilter
                  onFilterChange={handleFilterChange}
                  prevFilter={get(prevParams, 'language', null)}
                />
              </div>
            </td>
            <td className="border p-1 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>Offers</div>
                <UserOfferFilter onFilterChange={handleFilterChange} prevFilter={get(prevParams, 'has_offer', null)} />
              </div>
            </td>

            {isAdmin && (
              <td className="border p-1 text-sm font-bold text-blue-950">
                <div className="flex justify-between items-center relative">
                  <div ref={iconButtonRef}>Manual Intent</div>
                  <UserIntentFilter
                    intentType={'manual_intent'}
                    onFilterChange={handleFilterChange}
                    prevFilter={get(prevParams, 'manual_intent', [])}
                  />
                </div>
              </td>
            )}
            {isAdmin && (
              <td className="border p-1 text-sm font-bold text-blue-950">
                <div className="flex justify-between items-center relative">
                  <div ref={iconButtonRef}>Audited By</div>
                  <UserAuditedByFilter
                    onFilterChange={handleFilterChange}
                    prevFilter={get(prevParams, 'audited_by', null)}
                  />
                </div>
              </td>
            )}
            <td className="border p-1 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>Call Started at</div>
                <UserDateRangeFilter
                  onFilterChange={handleFilterChange}
                  prevFilter={{
                    start_date: get(prevParams, 'start_date', null),
                    end_date: get(prevParams, 'end_date', null),
                    dateRangeFilterType: get(prevParams, 'dateRangeFilterType', DateRangeFilterTypes.LastXMinutes),
                    defaultDateRangeFilter: get(prevParams, 'defaultDateRangeFilter', true),
                  }}
                />
              </div>
            </td>
            {false && <td className="border p-1 text-sm font-bold text-blue-950">Call Recording</td>}
          </tr>
        </thead>
        <tbody>
          {actualCalls?.length === 0 ? (
            <tr className="w-full h-20 p-10 flex justify-center items-center font-medium text-sm">
              No data found, Please change the page to get more data
            </tr>
          ) : (
            actualCalls?.map((log, index) => {
              const isSelected = selectedItems.findIndex((i) => i.id === log.id) !== -1;
              return (
                <tr
                  className={twMerge(
                    'text-sm p-2 hover:bg-gray-200 cursor-pointer transition-all ease-in-out w-full',
                    isSelected ? 'bg-blue-100' : '',
                  )}
                  key={`${log.call_sid}-${index}`}
                  onClick={() => onRowPress(log.call_sid)}
                >
                  {enableRowSelection && (
                    <td className="border p-2">
                      <Checkbox checked={isSelected} onClick={(e) => onSelectRow(e, log)} />
                    </td>
                  )}
                  <td className="border p-2">{(page - 1) * size + index + 1}</td>
                  <td className="border p-2">{log.borrower_name}</td>
                  <td className="border p-2">{log.call_to}</td>
                  <td className="border p-2">{log.duration}s</td>
                  <td className="border p-2">{getUserIntentReason(log.intent)}</td>
                  <td className="border p-2 min-w-[75px]">{log.dpd}</td>
                  <td className="border p-2 min-w-[75px]">
                    {get(log, 'borrower_language') ? getEnumKeyByValue(Languages, get(log, 'borrower_language')) : '-'}
                  </td>
                  <td className="border p-2">{log.offer_treatment}</td>
                  {isAdmin && <td className="border p-2">{getUserIntentReason(log.manual_intent)}</td>}
                  {isAdmin && <td className="border p-2">{log.audited_by}</td>}
                  <td className="border p-2">{moment(log.call_start).format('lll')}</td>
                  {false && (
                    <td className="border p-2">
                      <InlineAudioBar callId={log.call_sid} />
                    </td>
                  )}
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <div className="flex justify-start items-center gap-x-2">
        <SecondaryButton className="w-10" onClick={() => updatePage(page - 1)} disabled={page === 1}>
          Prev
        </SecondaryButton>
        <Tooltip title="Enter page number you want to see ">
          <div className="min-w-20">
            <TextField
              type="number"
              value={pageValue}
              onChange={(v) => setPageValue(v.target.value)}
              onBlur={handlePageInputBlur}
            />
          </div>
        </Tooltip>

        <SecondaryButton className="w-10" onClick={() => updatePage(page + 1)} disabled={disableNext}>
          Next
        </SecondaryButton>
      </div>
    </div>
  );
}
