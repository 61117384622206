import { TextField } from '@components/TextField';
import { PrimaryButton } from '@components/Buttons';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetCompanyList } from 'src/hooks/apis/company';
import { Dropdown } from '@components/Dropdown';
import { useEffect, useState } from 'react';
import { AdminUsers } from 'src/constants/users';
import { CompanyUser } from 'src/typings/user.types';

type FormData = {
  key: string;
};

const userOptions = AdminUsers.map((user) => {
  return {
    name: user.first_name + ' ' + user?.last_name,
    id: user.id,
  };
});

export default function LoginContainer() {
  const navigateTo = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<CompanyUser | null>(null);
  const [apiKey, setAPIKey] = useState<string | null>(null);

  const referrer: string = searchParams.get('referrer') || '';

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
    getValues,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      key: '',
    },
    mode: 'onSubmit',
  });

  const { data, isLoading, error } = useGetCompanyList({ enabled: !!apiKey });

  const handleUserChange = (val: any) => {
    if (val) {
      const user = AdminUsers.find((u) => u.id === val.id);
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
      }
    }
  };

  const onLogin = () => {
    if (referrer) {
      // Navigate to referrer currently present in url
      navigateTo(referrer, { replace: true });
    } else {
      navigateTo('/dashboard', { replace: true });
    }
    toast.success(user ? `Welcome back, ${user.first_name}!` : 'Authenticated successfully.');
  };

  const onSubmit = async () => {
    if (!apiKey) {
      const val = getValues().key;
      localStorage.setItem('en', val);
      setAPIKey(val);
      return;
    }

    onLogin();
  };

  useEffect(() => {
    if (apiKey && data?.length === 1) {
      onLogin();
    }
  }, [data]);

  const disableSubmit = apiKey ? !user : isLoading || !isValid || isSubmitting;

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center gap-y-4 md:gap-y-6">
      <div className="flex flex-col items-start justify-start w-full lg:w-1/3 gap-2 p-5">
        <div className="text-3xl text-primary font-semibold">Welcome to Knowl!</div>
      </div>

      <form className="w-full lg:w-1/3 flex flex-col gap-y-5 p-5" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Key"
          placeholder="Please enter a Valid Key"
          type="password"
          mandatory
          {...register('key', {
            required: 'This field is mandatory',
            setValueAs: (value: string) => value.trim(),
          })}
        />
        {apiKey && !isLoading && !error && data?.length > 1 && (
          <div className="w-full">
            <Dropdown
              mandatory
              placeholder="Select User"
              options={userOptions}
              onSelect={handleUserChange}
              label="User"
            />
          </div>
        )}
        <div className="w-1/2">
          <PrimaryButton
            propClass="text-sm lg:text-md w-24 lg:w-32"
            type="submit"
            disabled={disableSubmit} //{isLoading || !isValid || isSubmitting}
          >
            {apiKey ? 'Login' : 'Submit'}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}
