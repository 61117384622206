import { FilterIcon } from '@heroicons/react/outline';
import { Checkbox, IconButton, Popover } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { InputLabel } from '@components/TextField';
import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { convertUTCToIST } from '@utils/time.utils';
import { checkIfStale, DEFAULT_TIME_RANGE, getDefaultStartAndEndTime } from './utils';
import { DateRangeFilterTypes } from 'src/typings/call.types';

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: DateRangeFilter | null;
};

type DateRangeFilter = {
  start_date: string;
  end_date: string;
  dateRangeFilterType: DateRangeFilterTypes;
  defaultDateRangeFilter: boolean;
};

const dateRangeOptions = [
  { value: DateRangeFilterTypes.LastXMinutes, label: `Last ${DEFAULT_TIME_RANGE} Minutes` },
  { value: DateRangeFilterTypes.Today, label: 'Today [8AM - Now]' },
  { value: DateRangeFilterTypes.Yesterday, label: 'Yesterday [8AM - 7PM]' },
  { value: DateRangeFilterTypes.Custom, label: 'Custom' },
];

export default function UserDateRangeFilter({ onFilterChange, prevFilter }: Props) {
  const iconRef = useRef(null);
  const [selectedFilters, setSelectedFilters] = useState<DateRangeFilter | null>(prevFilter || null);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [dateRangeType, setDateRangeType] = useState<DateRangeFilterTypes>(
    prevFilter?.dateRangeFilterType || DateRangeFilterTypes.LastXMinutes,
  );

  const onSetDateRange = (start: Date, end: Date, isDefault: boolean, type: DateRangeFilterTypes) => {
    if (!start) return;
    const payload = {
      start_date: moment(start).utc().format('YYYY-MM-DDTHH:mm:ss'),
      end_date: end ? moment(end).utc().format('YYYY-MM-DDTHH:mm:ss') : null,
      dateRangeFilterType: type || dateRangeType,
      defaultDateRangeFilter: isDefault,
    };
    setSelectedFilters(payload);
    onFilterChange(payload);
    toggleShowFilter(false);
  };

  const onClearFilter = () => {
    setSelectedFilters(null);
    setDefaultDateFilter();
  };

  const setDefaultDateFilter = (type: DateRangeFilterTypes = DateRangeFilterTypes.LastXMinutes) => {
    const { start, end } = getDefaultStartAndEndTime(type);
    setStartDate(start);
    setEndDate(end);
    onSetDateRange(start, end, true, type);
  };

  const setPrevDateFilter = () => {
    if (prevFilter?.start_date) {
      const modifiedStartDate = convertUTCToIST(prevFilter.start_date);
      setStartDate(modifiedStartDate);
    }
    if (prevFilter?.end_date) {
      const modifiedEndDate = convertUTCToIST(prevFilter.end_date);
      setEndDate(modifiedEndDate);
    }
  };

  const selectDateRange = (val: DateRangeFilterTypes) => {
    setDateRangeType(val);
    if (val === DateRangeFilterTypes.Custom) return;
    const { start, end } = getDefaultStartAndEndTime(val);
    setStartDate(start);
    setEndDate(end);
    onSetDateRange(start, end, true, val);
  };

  useEffect(() => {
    if (prevFilter?.start_date && prevFilter?.end_date) {
      const type = prevFilter.dateRangeFilterType;

      if (prevFilter.dateRangeFilterType !== DateRangeFilterTypes.Custom) {
        const isStale = checkIfStale(prevFilter?.start_date, prevFilter?.end_date, type);
        if (isStale) {
          setDefaultDateFilter(type);
        } else {
          setPrevDateFilter();
        }
      } else {
        setPrevDateFilter();
      }
    } else {
      setDefaultDateFilter();
    }
  }, [prevFilter]);

  return (
    <>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {selectedFilters?.start_date !== null && selectedFilters?.end_date !== null && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            1
          </div>
        )}
      </div>

      <Popover
        open={showFilter}
        onClose={() => toggleShowFilter(false)}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: 50, // 50 pixels offset from the top of the page
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col justify-start gap-y-4 w-[550px] max-h-[590px] p-2">
          <div className="flex flex-col gap-2 pl-4">
            {dateRangeOptions.map((option) => {
              return (
                <div
                  key={option.value}
                  className="flex justify-start items-center gap-x-1 cursor-pointer hover:rounded-lg hover:bg-gray-100 transition-colors ease-in-out"
                  onClick={() => selectDateRange(option.value)}
                >
                  <Checkbox checked={dateRangeType === option.value} />
                  <div className="text-[14px] font-normal">{option.label}</div>
                </div>
              );
            })}
          </div>
          {dateRangeType === DateRangeFilterTypes.Custom && (
            <div className="flex justify-center items-start gap-x-2 ">
              <div className="flex flex-col">
                <InputLabel label={`Start time: ${startDate ? moment(startDate).format('lll') : ''}`} />
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  inline
                  placeholderText="Select start date"
                  className="min-w-[250px] h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                />
              </div>
              <div className="flex flex-col">
                <InputLabel label={`End time: ${endDate ? moment(endDate).format('lll') : ''}`} />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  selectsEnd
                  inline
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  placeholderText="Select end date"
                  className="min-w-[250px] h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                />
              </div>
            </div>
          )}
          <div className="w-full flex justify-end items-center gap-x-2 mr-10">
            <SecondaryButton onClick={onClearFilter} propClass="max-w-[100px] w-[100px] h-[30px]">
              Clear
            </SecondaryButton>
            <PrimaryButton
              onClick={() => onSetDateRange(startDate, endDate, false, dateRangeType)}
              propClass="max-w-[30px] w-[30px] h-[30px]"
            >
              Go
            </PrimaryButton>
          </div>
        </div>
        {/* <div className="flex flex-col justify-center items-center w-[250px] h-[250px] rounded-lg overflow-hidden">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => onSetDateRange(update)}
            isClearable={true}
            inline
            // timeInputLabel="Time:"
            // showTimeInput
            className="min-w-[250px] h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
          />
        </div> */}
      </Popover>
    </>
  );
}
