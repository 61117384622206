import { UserIcon } from '@heroicons/react/outline';
import { colorMap, getInitials } from '@utils/userNameUtils';
import { useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

type AvatarProps = {
  firstName?: string;
  lastName?: string;
  index?: number;
  onClick?: () => void;
  userColor?: string;
  customClass?: string;
};

export const Avatar = ({ firstName, index, userColor, lastName, onClick, customClass }: AvatarProps) => {
  const color = useMemo(
    () => userColor || colorMap[index ?? Math.floor(Math.random() * (colorMap.length - 1))],
    [userColor, index],
  );

  const handleProfileClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <div
      style={{ backgroundColor: color }}
      className={twMerge(
        onClick ? 'cursor-pointer' : '',
        'flex justify-center text-textPrimary items-center text-sm font-bold w-10 h-8 rounded-full transition-all',
        customClass ? customClass : '',
      )}
      onClick={handleProfileClick}
    >
      {firstName || lastName ? getInitials(firstName, lastName) : <UserIcon className="w-5 h-5" />}
    </div>
  );
};
